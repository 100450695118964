<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Recetas </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12" sm="6">
            <BuscarProducto
              :rules="reglas.requerido"
              :label="`Producto`"
              class="mb-4 mt-2"
              @GetProducto="GetProducto"
              ref="RefProducto"
            />
            <SelectTipoReceta
              @change="changeTipoReceta(datos.tipo_receta)"
              :rules="reglas.requerido"
              v-model="datos.tipo_receta"
              :add="false"
              :dense="true"
              :outlined="true"
            />
            <v-textarea
              v-model="datos.descripcion"
              :rules="reglas.requerido"
              label="Descripcion"
              class="mt-4"
              outlined
              dense
              rows="3"
              hide-details="auto"
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              class="mt-2"
              :rules="reglas.requerido"
              v-model="datos.costo"
              @keyup="changePvp('costo')"
              :label="`COSTO x ${datos.producto.unidad_medida.unidad}`"
              required
              outlined
              dense
              hide-details="auto"
              disabled
            ></v-text-field>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="mt-4"
                  :rules="reglas.requerido"
                  v-model="datos.utilidad"
                  @keyup="changePvp('utilidad')"
                  label="Utilidad"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="mt-4"
                  :rules="reglas.requerido"
                  v-model="datos.margenUtilidad"
                  @keyup="changePvp('margen')"
                  label="Margen"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  v-model="datos.pvp"
                  @keyup="changePvp('pvp')"
                  :label="`PVP x ${datos.producto.unidad_medida.unidad}`"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :disabled="datos.tipo_receta == 2"
                  :rules="reglas.requerido"
                  v-model="datos.cantidad"
                  @keyup="changePvp('costo')"
                  label="Cantidad"
                  :suffix="datos.producto.unidad_medida.unidad"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="datos.tipo_receta == 2">
                <v-divider class="mt-4"></v-divider>

                <v-checkbox
                  :disabled="!(datos.receta_detalle.length >= 2)"
                  v-model="datos.ind_variables"
                  hide-details="auto"
                  :label="`Acompañantes`"
                ></v-checkbox>
                <small>Opcion para configurar ingredientes acompañantes</small> <br />
                <small v-if="!(datos.receta_detalle.length >= 2)" class="info--text"
                  >Para activar esta opcion debe tener mas de 2 ingredientes</small
                >
                <small
                  v-if="datos.ind_variables && !(datos.receta_detalle.filter(item => item.ind_variable).length >= 2)"
                  class="error--text"
                  >Debe tener por lo menos 2 ingredientes con el indicador acompañantes</small
                >
              </v-col>
              <v-col cols="6" v-if="datos.ind_variables && datos.tipo_receta == 2">
                <v-text-field
                  :rules="reglas.requerido"
                  v-model="datos.variable_minimo"
                  label="Minimo"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="datos.ind_variables && datos.tipo_receta == 2">
                <v-text-field
                  :rules="reglas.requerido"
                  v-model="datos.variable_maximo"
                  @keyup="calcularCosto()"
                  label="Maximo"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle> Detalles </v-card-subtitle>
      <v-card-text>
        <BuscarProducto
          :label="`Producto detalle`"
          class="mb-4 mt-2"
          @GetProducto="GetProductoDetalle"
          ref="RefProductoDetalle"
        />
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Id</th>
                <th class="text-left">Nombre</th>
                <th class="text-left">Medida</th>
                <th class="text-left">Cantidad</th>
                <th class="text-left">Costo Und</th>
                <th class="text-left">Costo Total</th>
                <th class="text-left">
                  Opcional
                  <TooltipInfo
                    :mensaje="`Un ingrediente opcional tiene la libertad de excluirlo al momento de comandar`"
                  />
                </th>
                <th class="text-left" v-if="datos.ind_variables">
                  Acompañantes
                  <TooltipInfo
                    :mensaje="`Son todos aquellos ingredientes que pueden ser cambiados, ejemplo una pepsi por una soda`"
                  />
                </th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datos.receta_detalle" :key="index">
                <td>{{ item.producto.id }}</td>
                <td>
                  {{ item.producto.nombre }} <br />

                  <small v-if="item.producto.receta.tipo_receta" class="info--text"> {{item.producto.receta.tipo_receta.descripcion}} </small>
                </td>
                <td>{{ item.producto.unidad_medida.descripcion }}</td>
                <td>
                  <v-text-field
                    @keyup="changePvp('costo')"
                    style="width: 100px"
                    class="mt-1"
                    hide-details=""
                    v-model="item.cantidad"
                    dense
                    outlined
                  ></v-text-field>
                </td>
                <td>{{ item.producto.costo }}</td>
                <td>{{ item.producto.costo * item.cantidad }}</td>
                <td>
                  <v-checkbox
                    v-show="item.ind_variable == false"
                    class="mt-0"
                    v-model="item.opcional"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </td>
                <td v-show="datos.ind_variables == true">
                  <v-checkbox
                    v-show="item.opcional == false"
                    class="mt-0"
                    v-model="item.ind_variable"
                    @change="calcularCosto()"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </td>
                <td>
                  <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                    <v-icon small>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col class="text-right mt-4">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import BuscarProducto from '../../producto/componentes/BuscarProducto.vue'
import SelectTipoReceta from '@/views/sistema/inventario/maestro/tipo-receta/componentes/Select.vue'
import { mdiInformation, mdiDelete } from '@mdi/js'
import TooltipInfo from '@/components/TooltipInfo.vue'
import RecetaServices from '@/api/servicios/inventario/RecetaServices'
export default {
  watch: {
    'datos.ind_variables': function (news, olds) {
      if (!news) {
        this.limpiarValiable()
      }
    },
  },
  components: {
    BuscarProducto,
    SelectTipoReceta,
    TooltipInfo,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const RefProducto = ref(null)
    const datosInit = {
      producto: {
        unidad_medida: {
          unidad: 'U',
        },
      },
      tipo_receta: '0',
      descripcion: '',
      costo: '',
      utilidad: '',
      margenUtilidad: '',
      pvp: '',
      id_producto: '',
      receta_detalle: [],
      ind_variables: false,
      variable_minimo: 0,
      variable_maximo: 0,
      cantidad: 1,
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))
    const changeTipoReceta = dat => {
      if (dat == 1) {
        datos.value.cantidad = 1
        datos.value.ind_variables = false
        datos.value.variable_minimo = 0
        datos.value.variable_maximo = 0
      }
    }
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const eliminarProdcuto = item => {
      datos.value.receta_detalle = datos.value.receta_detalle.filter(ele => ele.id_producto != item.id_producto)

      if (datos.value.ind_variables) {
        if (datos.value.receta_detalle.length <= 2) {
          datos.value.ind_variables = false
        }
      }
    }
    const calcularCosto = () => {
      var sum = 0
      var maximo_costo_variable = 0
      datos.value.receta_detalle.forEach(element => {
        if (!element.ind_variable) {
          sum += element.producto.costo * element.cantidad
        } else {
          if (element.producto.costo * element.cantidad > maximo_costo_variable) {
            maximo_costo_variable = element.producto.costo * element.cantidad
          }
        }
      })
      if (datos.value.ind_variables) {
        sum = sum + maximo_costo_variable * datos.value.variable_maximo
      }
      datos.value.costo = sum / datos.value.cantidad
    }
    const limpiarValiable = () => {
      datos.value.receta_detalle.forEach(item => {
        item.ind_variable = false
      })
      datos.value.variable_maximo = 0
      datos.value.variable_minimo = 0
    }
    const changePvp = tipo => {
      calcularCosto()
      switch (tipo) {
        case 'costo':
          if (datos.value.pvp > 0) {
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(4)
          } else {
            datos.value.utilidad = 0.0
            datos.value.margenUtilidad = 100.0
            datos.value.pvp = datos.value.costo
          }
          break
        case 'pvp':
          if (datos.value.costo > 0) {
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(2)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
          } else {
            datos.value.utilidad = 0.0
            datos.value.margenUtilidad = 100
            datos.value.costo = datos.value.pvp
          }
          break
        case 'utilidad':
          if (datos.value.costo > 0) {
            datos.value.pvp = (datos.value.costo * (datos.value.utilidad / 100 + 1)).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
          } else {
            datos.value.costo = 1
            datos.value.pvp = (datos.value.costo * (datos.value.utilidad / 100 + 1)).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
          }
          break
        case 'margen':
          if (datos.value.costo > 0) {
            datos.value.pvp = ((datos.value.costo * 100) / datos.value.margenUtilidad).toFixed(4)
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(2)
          } else {
            datos.value.costo = 1
            datos.value.pvp = ((datos.value.costo * 100) / datos.value.margenUtilidad).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
          }
          break
        default:
          break
      }
    }

    const RefProductoDetalle = ref(null)
    const GetProductoDetalle = item => {
    
      if (item != undefined) {
        if (datos.value.producto.id != item.id) {
          if (datos.value.receta_detalle.find(ele => ele.id_producto == item.id) == undefined) {
            datos.value.receta_detalle.push({
              opcional: false,
              id_producto: item.id,
              cantidad: 1,
              producto: {
                id: item.id,
                nombre: item.nombre,
                costo: item.costo,
                unidad_medida: item.unidad_medida,
                receta: { tipo_receta: item.receta?.tipo_receta_json },
              },
            })
            changePvp('costo')
          } else {
            store.commit('setAlert', {
              message: 'Ya esta agregado.',
              type: 'warning',
            })
          }
        } else {
          store.commit('setAlert', {
            message: 'no puede ser ingrediente de si mismo',
            type: 'warning',
          })
        }
        setTimeout(() => {
          RefProductoDetalle.value.model = null
        }, 100)
      }
    }
    const GetProducto = dato => {
      console.log('producto', dato)
      datos.value = {
        producto: dato,
        tipo_receta: dato.receta.tipo_receta,
        descripcion: dato.receta.descripcion,
        costo: dato.costo,
        utilidad: dato.utilidad,
        pvp: dato.precio_venta,
        id_producto: dato.id,
        cantidad: dato.receta.cantidad?dato.receta.cantidad:1,
        variable_minimo: dato.receta.variable_minimo,
        variable_maximo: dato.receta.variable_maximo,
        ind_variables: dato.receta.ind_variables,
        receta_detalle: dato.receta.receta_detalle ?? [],
      }

      changePvp('costo')
    }

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      RefProducto.value.model = null
      RefProductoDetalle.value.model = null
      form.value.resetValidation()
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      limpiar()
      RefProducto.value.asignarPagoducto(item)
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      if (datos.value.ind_variables && !(datos.value.receta_detalle.filter(item => item.ind_variable).length >= 2)) {
        val = false
      }

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          RecetaServices.recetaActualizar({ ...datos.value })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista()
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: `,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
                  error: error,
                  funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      GetProducto,
      GetProductoDetalle,
      RefProductoDetalle,
      RefProducto,
      calcularCosto,
      changePvp,
      limpiarValiable,
      changeTipoReceta,
      icons: {
        mdiInformation,
        mdiDelete,
      },
      eliminarProdcuto,
    }
  },
}
</script>