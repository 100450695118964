
<template>
  <div>
    <v-card>
      <v-card-subtitle>Listar</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
                 
                  v-model="filtro.valor"
                  label="Nombre/descripcion/ref"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectProductoTipo v-model="filtro.id_producto_tipo" :add="false" :dense="true" :outlined="true"
              :clearable="true" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectEstado v-model="filtro.estado" :padre="2" :add="false" :dense="true" :outlined="true"
              :clearable="true" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectUnidadMedida v-model="filtro.id_unidad_medida" :add="false" :dense="true" :outlined="true"
              :clearable="true" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectCategoria v-model="filtro.id_categoria" :add="false" :dense="true" :outlined="true" 
              :clearable="true"/>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectSubCategoria
              v-model="filtro.id_sub_categoria"
              :padre="filtro.categoria"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn class="primary" @click="cargar()" :loading="cargandoDatos" small>Buscar</v-btn> 
          </v-col> 
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nombre</th>
                <th class="text-left">Tipo</th>
                <th class="text-left">Medida</th>
                <th class="text-left">Categoria</th>
                <th class="text-left">Costo</th>
                <th class="text-left">Utilidad</th>
                <th class="text-left">PVP</th>   
                <th class="text-left">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Lista" :key="item.id">
                <td class="text-center">
                  <small>{{ item.id }}</small
                  ><br />
                  <v-avatar size="40">
                    <v-img
                      :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                      :src="`${config.api_imagenes}/assets/images/product/${item.img}`"
                      alt="John"
                    />
                  </v-avatar>
                </td>
                <td>{{ item.nombre }}</td>
                <td>{{ item.receta.tipo_receta_json.descripcion }}</td>
                <td>{{ item.unidad_medida.descripcion }}</td>
                <td>
                  <small>{{ item.categoria.nombre }}</small>
                  <br />
                  <small>{{ item.sub_categoria.nombre }}</small>
                </td>
                <td>{{ item.costo }}</td>
                <td>{{ item.utilidad }}</td>
                <td>{{ item.precio_venta }}</td>  
             
                <td>
                  <v-btn icon>
                    <v-icon color="primary" @click="GetEditar(item)">{{ icons.mdiPencil }}</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
      <!-- Table -->
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import RecetaService from '@/api/servicios/inventario/RecetaServices'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCategoria from '../../maestro/sub-categoria/componentes/Select.vue'
import SelectUnidadMedida from '../../maestro/unidad-medida/componentes/Select.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'

import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'

export default {
  components: {
    SelectCategoria,
    SelectSubCategoria,
    SelectUnidadMedida,
    SelectEstado,
    SelectProductoTipo,
  },
  setup(props, context) {
    const logoI = process.env.VUE_APP_IMG_LOGO
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const filtroInit = {
      valor: '',
      id_categoria: null,
      id_sub_categoria: null,
      id_unidad_medida: null,
      id_producto_tipo: null,
      estado: null,
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })

    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const cargar = () => {
      cargandoDatos.value = true
      try {
        RecetaService.productoRecetaListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
                message: error,
                type: error,
              })
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Activo', value: 'indActivo' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      filtro,
      logoI
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  